import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'breads-root',
    templateUrl: './breads-main.component.html',
    styleUrls: ['./breads-main.component.scss']
})
export class BreadsMainComponent implements OnInit {

    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.translateService.setDefaultLang('en');
        this.translateService.use('en');
    }
}
