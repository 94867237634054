<footer class="footer">
    <div class="container">
        <div class="text-center">
            <a href="https://brightviewradiology.com/" target="_blank"><img class="footer-logo" src="../../../../../../assets/img/logo-outlined.png" alt=""></a>
        </div>
        <hr>
        <div class="footer-social text-center">
            <a href="https://www.facebook.com/Brightview-Radiology-161629953860838 "><span class="ri-facebook-box-fill"></span></a>
            <a href="https://www.linkedin.com/company/radiology-film-reading-services/"><span class="ri-linkedin-fill"></span></a>
        </div>
        <p class="footer-copy text-center">&copy; 2010 - 2020</p>
    </div>
</footer>
