import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreadsMainComponent } from './modules/b-reads-marketing/breads-main.component';
import { HomeComponent } from './modules/b-reads-marketing/pages/home/home.component';
import { ErrorPagesComponent } from './modules/shared/components';

const routes: Routes = [
  { path: 'b-read', loadChildren: () => import('./modules/b-reads-marketing/b-reads-marketing.module').then(m => m.BReadsMarketingModule) },
  { path: '', loadChildren: () => import('./modules/main-marketing/marketing.module').then(m => m.MarketingModule) },
  { path: 'not_found', component: ErrorPagesComponent },
  { path: '**', redirectTo: 'not_found' }
];

const bReadRoutes: Routes = [
  {
    path: '', component: BreadsMainComponent, children: [
      { path: '', component: HomeComponent },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ]
  },
  { path: 'not_found', component: ErrorPagesComponent },
  { path: '**', redirectTo: 'not_found' }
];

function isBReadDomain() {
  // PROD implementation
  if (window.location.host.includes('b-read.com')) {
    return true;
  } else {
    return false;
  }

  // For testing run app with ng serve --port 4201 and it will simulate b-read.com domain
  // return window.location.port === '4201';
}

@NgModule({
  imports: [RouterModule.forRoot(isBReadDomain() ? bReadRoutes : routes, { onSameUrlNavigation: 'reload', /* enableTracing:true */ })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
