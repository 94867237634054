export const environment = {
  // production: true,

  // serverURL: 'https://bedwax.ebdev',
  serverURL: 'https://api.bvrad.com',
  marketingContactSheet: '1itEUr1QNaCDttqFt_jZZGoL9LTg22YEXGEPJoi-11G4',

  // cloudFunctionsUrl : 'https://us-central1-fir-demo-ede56.cloudfunctions.net',
  cloudFunctionsUrl : 'https://bedwax.ebdev/heroic-goal-278716/us-central1',

  vendorTypes: [
    "radiologist",
    "transcriptionist"
  ],

  firebaseConfig : {
    apiKey: "AIzaSyAXUwmEfTOIy_vKVE6NX5HPPpk3OHo_eoE",
    authDomain: "heroic-goal-278716.firebaseapp.com",
    databaseURL: "https://heroic-goal-278716.firebaseio.com",
    projectId: "heroic-goal-278716",
    storageBucket: "heroic-goal-278716.appspot.com",
    messagingSenderId: "903609461684",
    appId: "1:903609461684:web:1a02f136ea142e148060e1"
  }
};
