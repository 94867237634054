import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute } from '@angular/router';
import { GTagService } from 'src/app/modules/shared/services/gtag.service';

@Component({
    selector: 'breads-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public contactFrom: FormGroup;
    public formSubmiting: boolean;
    public formSubmitted: boolean;
    public formHasErrors: boolean;
    @ViewChild('formDirective') formDirective: any;

    constructor(private fireFunctions: AngularFireFunctions, private cd: ChangeDetectorRef, private route: ActivatedRoute, private gtag: GTagService) {}

    ngOnInit() {
        this.formSubmiting = false;
        this.formSubmitted = false;
        this.formHasErrors = false;

        this.initForm();
        this.contactFrom.updateValueAndValidity();
    }

    public initForm() {
        const storageForm = JSON.parse(localStorage.getItem('contact-form')) ? JSON.parse(localStorage.getItem('contact-form')) : {};

        this.contactFrom = new FormGroup({
          firstName: new FormControl(storageForm.firstName, Validators.required),
          lastName: new FormControl(storageForm.lastName, Validators.required),
          email: new FormControl(storageForm.email, [Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]),
          phoneNumber: new FormControl(storageForm.phoneNumber),
          city: new FormControl(storageForm.city),
          company: new FormControl(storageForm.interest),
          message: new FormControl(storageForm.message)
        });

        this.contactFrom.valueChanges.subscribe(val => {
          this.setFormToStorage();

          if (this.contactFrom.valid) {
            this.formHasErrors = false;
          }
        });
      }

    public setFormToStorage() {
      localStorage.setItem('contact-form', JSON.stringify(this.contactFrom.getRawValue()));
    }

    public submitForm() {
      const label = this.route.snapshot.queryParamMap.get('id') || 'Contact';
      this.gtag.triggerCustomGtagEvent('Submit', 'Request quote', 'Form-Submit', label);
      this.formHasErrors = false;
      if (this.contactFrom.valid) {
        this.formSubmiting = true;
        this.fireFunctions.httpsCallable('sendContactCorm')(Object.assign({spreadsheetId: environment.marketingContactSheet}, this.contactFrom.getRawValue())).subscribe((res: any) => {
          this.contactFrom.reset();
          this.formDirective.resetForm();
          this.formSubmiting = false;
          this.showConfirmation();
        });
      } else {
        this.formHasErrors = true;
      }
    }

    public showConfirmation() {
      this.formSubmitted = true;

      setTimeout(() => {
        this.formSubmitted = false;
        this.cd.detectChanges();
      }, 5000);
    }

    public goToContactForm(customEventAction = 'Contact-Button-Click') {
      this.gtag.triggerGtagClickEvent('Behaviour', customEventAction, window.location.href);
      window.scrollTo(0, document.getElementById('contact-form').offsetTop);
    }
}
