import { Directive, HostListener, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    @Output() clickOutside: EventEmitter<any> = new EventEmitter<any>();

    constructor(private elemRef: ElementRef) {}

    @HostListener('document:click', ['$event'])
    onClick(event: any) {
        if (!this.elemRef.nativeElement.contains(event.target)) {
            this.clickOutside.emit(event);
        }
    }
}