import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'breads-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class BreadsFooterComponent implements OnInit {
    public year: number;

    constructor() { }

    ngOnInit() {
        this.year = new Date().getFullYear();
    }

    public goToTop(): void {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
}
