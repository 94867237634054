<div class="breads-homepage">
    <div class="breads-homepage-s01">
        <div class="container">
            <div class="row">
                <div class="col-6 valign-wrapper">
                    <div class="breads-homepage-s01-item">
                        <h1>{{'pages.breads.headings.0' | translate}}<br>{{'pages.breads.headings.1' | translate}}</h1>
                        <p>{{'pages.breads.descriptions.0' | translate}}</p>
                        <button class="button button-blue button-medium button-wide" (click)="goToContactForm('Hero-Button-Click')">{{'buttons.getStarted' | translate}}</button>
                    </div>
                </div>
                <div class="col-6">
                    <img src="../../../../../assets/img/marketing/b-read-form.jpg" alt="form"/>
                </div>
            </div>
        </div>
    </div>
    <div class="breads-homepage-s02">
        <div class="container narrow">
            <h2 class="text-center">{{'pages.breads.headings.2' | translate}}</h2>
            <p class="text-center">{{'pages.breads.descriptions.1' | translate}}</p>
            <div class="row">
                <div class="col-3">
                    <div class="breads-homepage-s02-item valign-wrapper justify-center"><span>United States Navy</span></div>
                </div>
                <div class="col-3">
                    <div class="breads-homepage-s02-item valign-wrapper justify-center"><span>United States Postal Service</span></div>
                </div>
                <div class="col-3">
                    <div class="breads-homepage-s02-item valign-wrapper justify-center"><span>U.S. HealthWorks</span></div>
                </div>
                <div class="col-3">
                    <div class="breads-homepage-s02-item valign-wrapper justify-center"><span>US Immigration & Naturalization</span></div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="breads-homepage-s02-item valign-wrapper justify-center"><span>Concentra Medical Centers</span></div>
                </div>
                <div class="col-3">
                    <div class="breads-homepage-s02-item valign-wrapper justify-center"><span>Mobile Medical</span></div>
                </div>
                <div class="col-3">
                    <div class="breads-homepage-s02-item valign-wrapper justify-center"><span>NY State Department of Health</span></div>
                </div>
                <div class="col-3">
                    <div class="breads-homepage-s02-item valign-wrapper justify-center"><span>NYC Dept. of Health & Mental Hygiene</span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="breads-homepage-s03">
        <div class="container narrower">
            <h2 class="text-center">{{'pages.breads.headings.3' | translate}}</h2>
            <p class="text-center">{{'pages.breads.descriptions.2' | translate}}</p>
            <p class="text-center">{{'pages.breads.descriptions.3' | translate}}</p>
            <p class="text-center">{{'pages.breads.descriptions.4' | translate}}</p>
            <p class="text-center">{{'pages.breads.descriptions.5' | translate}}</p>
        </div>
    </div>

    <div class="breads-homepage-s04">
        <div class="container">
            <div class="row mobile-reverse">
                <div class="col-6 valign-wrapper">
                    <div class="breads-homepage-s04-item">
                        <h3>{{'pages.breads.headings.4' | translate}}</h3>
                        <p>{{'pages.breads.descriptions.6' | translate}} ​</p>
                        <p>{{'pages.breads.descriptions.7' | translate}}</p>
                    </div>
                </div>
                <div class="col-6 text-center">
                    <img src="../../../../../assets/img/marketing/radiologist-01.jpg" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="breads-homepage-s05">
        <div class="container">
            <div class="row">
                <div class="col-6 text-center">
                    <img src="../../../../../assets/img/cog-with-clock.png" alt="">
                </div>
                <div class="col-6 valign-wrapper">
                    <div class="breads-homepage-s05-item">
                        <h3>{{'pages.breads.headings.5' | translate}}</h3>
                        <p>{{'pages.breads.descriptions.8' | translate}}</p>
                        <p>{{'pages.breads.descriptions.9' | translate}} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="breads-homepage-s06">
        <div class="container">
            <div class="row mobile-reverse">
                <div class="col-6 valign-wrapper">
                    <div class="breads-homepage-s06-item">
                        <h3>{{'pages.breads.headings.6' | translate}}</h3>
                        <p>{{'pages.breads.descriptions.10' | translate}}</p>
                        <p>{{'pages.breads.descriptions.11' | translate}}</p>
                        <p>{{'pages.breads.descriptions.12' | translate}} <a class="inline-link" [routerLink]="['/welcome/contact']">{{'pages.breads.descriptions.13' | translate}}</a> {{'pages.breads.descriptions.14' | translate}}</p>
                    </div>
                </div>
                <div class="col-6 text-center">
                    <img src="../../../../../assets/img/rating-with-clock.png" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="breads-homepage-s07">
        <div class="container">
            <h2 class="text-center">{{'pages.breads.descriptions.15' | translate}}</h2>
            <p class="text-center">
                <button class="button button-white button-medium button-wide" (click)="goToContactForm()">{{'buttons.startTheConversation' | translate}}</button>
            </p>
        </div>
    </div>

    <div class="breads-homepage-s08">
        <div class="container">
            <h2 class="text-center">{{'pages.common.whyShouldYou.heading' | translate}}</h2>
            <p class="text-center">{{'pages.common.whyShouldYou.meta.0' | translate}}</p>
            <p class="text-center">{{'pages.common.whyShouldYou.meta.1' | translate}}</p>
            <div class="row">
                <div class="col-3">
                    <div class="breads-homepage-s08-item">
                        <img src="../../../../../assets/img/man-with-rating.png" alt="">
                        <h4>{{'pages.common.whyShouldYou.items.0.heading' | translate}}</h4>
                        <p>{{'pages.common.whyShouldYou.items.0.meta' | translate}}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="breads-homepage-s08-item">
                        <img src="../../../../../assets/img/award.png" alt="">
                        <h4>{{'pages.common.whyShouldYou.items.1.heading' | translate}}</h4>
                        <p>{{'pages.common.whyShouldYou.items.1.meta' | translate}}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="breads-homepage-s08-item">
                        <img src="../../../../../assets/img/certified.png" alt="">
                        <h4>{{'pages.common.whyShouldYou.items.2.heading' | translate}}</h4>
                        <p>{{'pages.common.whyShouldYou.items.2.meta' | translate}}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="breads-homepage-s08-item">
                        <img src="../../../../../assets/img/cog-with-arrows.png" alt="">
                        <h4>{{'pages.common.whyShouldYou.items.3.heading' | translate}}</h4>
                        <p>{{'pages.common.whyShouldYou.items.3.meta' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="breads-homepage-s09">
        <div class="container text-center">
            <h2>{{'pages.common.testimonials.heading' | translate}}</h2>
            <div class="breads-homepage-s09-item">
                <p>&quot;{{'pages.common.testimonials.items.0.text' | translate}}&quot;</p>
                <h4>{{'pages.common.testimonials.items.0.author' | translate}}</h4>
            </div>
        </div>
    </div>

    <div class="breads-homepage-s10">
        <div class="container">
            <h2 class="text-center">{{'pages.breads.headings.7' | translate}}</h2>
            <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <h4>What is the NIOSH B-Reader Program?</h4>
                    </mat-expansion-panel-header>
                    <p>In 1949, the International Labour Office (ILO) declared a set of standards for analyzing and recording visual radiographic abnormalities caused by the inhalation of dust. However, despite introducing an effective classification system, B-Readers were still disagreeing with each other on their interpretations.</p>
                    <p>This led to the introduction of the NIOSH B-Reader Program in 1974 and the extensive use of a B-Reader examination in 1978.</p>
                    <p>The NIOSH-B-Reader Program is in place to identify physicians qualified to perform reliable B-Reads for coal miners and others who are at risk from the inhalation of dust. A certified NIOSH B-Reader is involved in the epidemiologic evaluation, surveillance and monitoring programs related to the risk of pneumoconiosis.</p>
                    <p>The NIOSH B-Reader Program is designed to ensure a competent and consistent level of radiographic reading is upheld. It evaluates the ability of the B-Readers to classify a test set of radiographs and consistently provide precise ILO Classifications. As it stands, there are under 300 NIOSH certified B-Readers in the US. </p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <h4>Why do I need a B-Reader?</h4>
                    </mat-expansion-panel-header>
                    <p>B-Readers play a critical role in providing ongoing medical surveillance for employees who are exposed to silica dust, coal mine dust and asbestos.</p>
                    <p>Following the Occupational Safety and Health Administration regulations, there’s been a greater need for B-Reader services.</p>
                    <p>A NIOSH certified B-Reader can gauge the extent of different types of pneumoconiosis, such as asbestosis, silicosis and black lung.</p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <h4>What is the ILO Classification?</h4>
                    </mat-expansion-panel-header>
                    <p>The <a href="#">ILO Classification</a> is a system of classifying chest radiographs for someone with a type of pneumoconiosis. The system states the name and the extent of different types of pneumoconiosis, including asbestosis, silicosis and black lung.</p>
                    <p>The ILO Classification System is designed to create a standardized means of interpreting abnormalities in chest X-rays that are the result of long-term dust inhalation.</p>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <h4>What are B-Read services?</h4>
                    </mat-expansion-panel-header>
                    <p>B-Reader services are performed by a physician certified by the National Institute for Occupational Safety and Health (NIOSH). It’s the job of the NIOSH B-Reader to classify chest X-rays and interpret the extent of dust exposure and other types of pneumoconiosis. This includes black lung, silicosis, asbestosis and other occupational respiratory diseases.</p>
                    <p>At Brightview Radiology, our team follows the NIOSH B-Reader Code of Ethics and uses the ILO Classification System to ensure we can provide our clients with true and reliable B-Reads.</p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    <div id="contact-form" class="breads-homepage-s11">
        <div class="container">
            <h2 class="text-center">{{'pages.breads.descriptions.16' | translate}}</h2>
            <form class="breads-contact-form" [formGroup]="contactFrom" #formDirective="ngForm">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>First name</mat-label>
                            <input matInput type="text" formControlName="firstName" required>
                        </mat-form-field>
                        <small *ngIf="contactFrom.get('firstName').touched && contactFrom.get('firstName').invalid" class="error-message">{{'errors.required' | translate}}</small>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Last name</mat-label>
                            <input matInput type="text" formControlName="lastName" required>
                        </mat-form-field>
                        <small *ngIf="contactFrom.get('lastName').touched && contactFrom.get('lastName').invalid" class="error-message">{{'errors.required' | translate}}</small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input #email matInput type="text" formControlName="email" required>
                        </mat-form-field>
                        <small *ngIf="contactFrom.get('email').touched && contactFrom.get('email').invalid" class="error-message">{{'errors.invalidEmail' | translate}}</small>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Phone number</mat-label>
                            <input matInput type="text" formControlName="phoneNumber">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>City</mat-label>
                            <input matInput type="text" formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Company name</mat-label>
                            <input matInput type="text" formControlName="company">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Message</mat-label>
                            <textarea matInput rows="5" formControlName="message"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="button button-white button-wide" [ngClass]="{'button-disabled': formSubmiting}" (click)="submitForm()">{{'buttons.submit' | translate}}</button>
                    </div>
                </div>
                
            </form>
            <div *ngIf="formSubmitted" class="confirmation-box">
                <p>Thank you for contacting us!</p>
            </div>
            <div *ngIf="formHasErrors" class="error-box">
                <p>Please fill in all the required fields!</p>
            </div>
        </div>
    </div>
</div> 