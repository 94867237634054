import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'breads-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class BreadsHeaderComponent implements OnInit {
    public isScrollingDown: boolean;
    public is_authenticated = false;
    public menuVisible: boolean;

    constructor(private router: Router) {
        this.isScrollingDown = false;
        this.menuVisible = false;
    }

    ngOnInit() {
        this.checkScrollPosition();
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        this.checkScrollPosition();
    }

    public checkScrollPosition() {
        const body = document.body;
        let doc = document.documentElement;
        doc = doc.clientHeight ? doc : body;

        this.isScrollingDown = doc.scrollTop !== 0; 
    }
}
