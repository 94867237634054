import { Injectable } from '@angular/core';

declare var gtag;

@Injectable({
    providedIn: 'root'
})
export class GTagService {
    public triggerGtagClickEvent(eventCategory: string, eventAction: string, eventLabel: string) {
        gtag('event', 'Click', {
            event_category: eventCategory,
            event_action: eventAction,
            event_label: eventLabel
        });
    }

    public triggerCustomGtagEvent(eventType: string, eventCategory: string, eventAction: string, eventLabel: string) {
        gtag('event', eventType, {
            event_category: eventCategory,
            event_action: eventAction,
            event_label: eventLabel
        });
    }
}
