import { NgModule ,ErrorHandler} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {ScrollingModule} from '@angular/cdk/scrolling';
import{MatChipsModule} from '@angular/material/chips';
import{MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ObserversModule} from '@angular/cdk/observers';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon/';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ButtonComponent } from './components';
import { KeysPipe } from './pipes';

// import {SharedRoutingModule} from './shared-routing.module';
import { ClickOutsideDirective ,displayEllipseDirective} from './directives';



@NgModule({
  declarations: [
    KeysPipe,
    ClickOutsideDirective,
    displayEllipseDirective,
    ButtonComponent
  ],
  exports: [
    KeysPipe,
    ClickOutsideDirective,
    ButtonComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatIconModule,
    MatSelectModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    DragDropModule,
    MatSortModule,
    MatPaginatorModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    ObserversModule,
    ScrollingModule,
  ],
  providers: [
    { provide: Window, useValue: window },
  ],
})
export class SharedModule { }
