import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SeoJsonLDService } from './modules/shared/services/seo-json-ld.service';
import { Title } from '@angular/platform-browser';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(router: Router, private jsonLDService: SeoJsonLDService, private title: Title) {
      const navEndEvent$ = router.events.pipe(
        filter(e => e instanceof NavigationEnd || e instanceof NavigationStart)
      );
      navEndEvent$.subscribe((e: NavigationEnd) => {
        if (e instanceof NavigationStart) {
          this.jsonLDService.onNavigationStart();
        } else if ( e instanceof NavigationEnd) {
          this.jsonLDService.onNavigationEnd(e.urlAfterRedirects === '/b-reads');
          gtag('config', 'GTM-T4S58TB', {
            pageTitle: title.getTitle(),
            pageCategory: 'contact',
            visitorType: e.urlAfterRedirects === '/home' ? 'low-value' : 'high-value'
          });
        }
      });
    }
}

