export const environment = {
  serverURL: 'localhost',
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyB1lHGdf3VDIIn4C-6NfTQ7QsSg0dF0cLk",
    authDomain: "fir-demo-ede56.firebaseapp.com",
    databaseURL: "https://fir-demo-ede56.firebaseio.com",
    projectId: "fir-demo-ede56",
    storageBucket: "fir-demo-ede56.appspot.com",
    messagingSenderId: "847708689310",
    appId: "1:847708689310:web:9bc6703b3399d7f4597c8f",
    measurementId: "G-1XLPB4SE1R"
  },

  
};



const saveMe = {
    "testontop": 4,
    "brightviewradiology_fax_number": "7187097022",
    "misc": {
      "api_convert_to_local_timestamp": [
        {
          "url_regex": "d",
          "tz": "America/New_York",
          "path": [
            "dos"
          ]
        }
      ]
    },
    "client_codes": [
      "ID",
      "IL",
      "IMOS",
      "IB",
      "IS",
      "II",
      "IU",
      "IE",
      "IX",
      "IY",
      "IO",
      "IG",
      "IH",
      "IQ",
      "IR",
      "IJ",
      "IT",
      "IPW",
      "IPM",
      "IPH",
      "IPT",
      "IPA",
      "IPS",
      "hello",
      "PA",
      "MAW"
    ],
    "fax_destination_identifer": "client_code",
    "fax_numbers": [
      {
        "name": "IY",
        "number": "5184648918"
      },
      {
        "name": "IX",
        "number": "9292812325"
      },
      {
        "name": "IU",
        "number": "7168772672"
      },
      {
        "name": "IT",
        "number": "9143688201"
      },
      {
        "name": "IS",
        "number": "3154235045"
      },
      {
        "name": "IR",
        "number": "7182374579"
      },
      {
        "name": "IQ",
        "number": "7185216208"
      },
      {
        "name": "IB",
        "number": "6072370862"
      },
      {
        "name": "IE",
        "number": "5854734918"
      },
      {
        "name": "IH",
        "number": "2123632655"
      },
      {
        "name": "II",
        "number": "3158648599"
      },
      {
        "name": "IJ",
        "number": "7164887283"
      },
      {
        "name": "IL",
        "number": "8453460676"
      },
      {
        "name": "IMOS",
        "number": "3143675982"
      },
      {
        "name": "IO",
        "number": "6316489069"
      },
      {
        "name": "IPM",
        "number": "7177954602"
      },
      {
        "name": "IPT",
        "number": "4129043729"
      },
      {
        "name": "IPA",
        "number": "6104372868"
      },
      {
        "name": "IPH",
        "number": "2677582442"
      },
      {
        "name": "IPW",
        "number": "5703382700"
      },
      {
        "name": "ID",
        "number": "5165052719"
      },
      {
        "name": "IG",
        "number": "8454523282"
      },
      {
        "name": "MAW",
        "number": "2123497529"
      }
    ],
    "invoice_config": {
      "allowedBillListItems": [
        {
          "name": "narrative-read",
          "price": "7.00"
        },
        {
          "name": "CR-B-read",
          "price": "20.00"
        },
        {
          "name": "other",
          "price": "8.67"
        },
        {
          "name": "addendum",
          "price": "8.47"
        }
      ],
      "allowedInvoiceListItems": [
        {
          "item": "IMA-BRWON",
          "price": "10.00"
        },
        {
          "item": "CR-B-read",
          "price": "20.00"
        },
        {
          "item": "IMA-CR-Study-Interpretation",
          "price": "8.67"
        },
        {
          "item": "IMA-X-ray-Study-Interpretation",
          "price": "8.47"
        },
        {
          "item": "mantaf",
          "price": "8.47"
        },
        {
          "item": "pdf",
          "price": "8.47"
        },
        {
          "item": "mantaf pdf",
          "price": "8.47"
        },
        {
          "item": "addendum special",
          "price": "8.47"
        },
        {
          "item": "IMA-BRWON",
          "price": "8.47"
        },
        {
          "item": "IMA-CR-Study-Interpretation",
          "price": "8.47"
        },
        {
          "item": "X-ray-B-read",
          "price": "8.47"
        }
      ]
    },
    "tables": {
      "config_find": {
        "requester": {
          "extra_request_options": {},
          "apiURl": "requester",
          "table_def": {
            "fields": {
              "email": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "name": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        },
        "institution": {
          "extra_request_options": {},
          "apiURl": "institution",
          "table_def": {
            "fields": {
              "code": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "name": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "time_zone": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        },
        "radiologist": {
          "extra_request_options": {},
          "apiURl": "radiologist",
          "table_def": {
            "fields": {
              "email": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "full_name": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        },
        "vendor": {
          "extra_request_options": {},
          "apiURl": "vendor",
          "table_def": {
            "fields": {
              "email": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "name": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        },
        "studyType": {
          "extra_request_options": {},
          "apiURl": "study_type",
          "table_def": {
            "fields": {
              "body_part": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "modality_type": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "views": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        }
      },
      "configFind_payloads": {
        "institution_config": {
          "institution": {
            "extra_request_options": {
              "filter_type": "group"
            },
            "apiURl": "study_service",
            "table_def": {
              "fields": {
                "code": {
                  "legalQueryOperators": [
                    "=",
                    "in",
                    "like",
                    "!="
                  ]
                },
                "name": {
                  "legalQueryOperators": [
                    "=",
                    "in",
                    "like",
                    "!="
                  ]
                },
                "time_zone": {
                  "legalQueryOperators": [
                    "=",
                    "in",
                    "like",
                    "!="
                  ]
                }
              },
              "order": [],
              "uniqColsVals": []
            }
          }
        },
        "radiologist_config": {
          "radiologist": {
            "fields": {
              "email": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "full_name": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        },
        "requester_config": {
          "requester": {
            "fields": {
              "email": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "name": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        },
        "studyType_config": {
          "study_type": {
            "fields": {
              "body_part": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "modality_type": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "views": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        },
        "vendor_config": {
          "vendor": {
            "fields": {
              "email": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              },
              "name": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like",
                  "!="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        }
      },
      "studyService_worklist": {
        "all": {
          "extra_request_options": {
            "filter_type": "group"
          },
          "apiURl": "study_service",
          "table_def": {
            "fields": {
              "patient_name": {
                "legalQueryOperators": [
                  "=",
                  "like"
                ]
              },
              "study_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "service_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "App.StudyServiceDef.OLD_TRANSCRIBE.client_code": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "body_part": {
                "legalQueryOperators": [
                  "in",
                  "=",
                  "like"
                ]
              },
              "signed_time": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              }
            },
            "filter_type": "group",
            "order": [],
            "uniqColsVals": []
          }
        },
        "ima": {
          "extra_request_options": {
            "filter_type": "group"
          },
          "apiURl": "study_service",
          "table_def": {
            "default_filter": [
              {
                "filter_only": true,
                "filter_value": "NULL_QUERY",
                "fqdn": "canceled_time",
                "query_operator": "="
              },
              {
                "filter_only": true,
                "filter_value": "narrative",
                "fqdn": "report_type",
                "query_operator": "="
              },
              {
                "filter_only": true,
                "filter_value": "OLD_TRANSCRIBE OLD_IMA",
                "fqdn": "App.StudyServiceDef",
                "query_operator": "="
              }
            ],
            "fields": {
              "patient_name": {
                "legalQueryOperators": [
                  "=",
                  "like"
                ]
              },
              "study_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "service_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "App.StudyServiceDef.OLD_TRANSCRIBE.OLD_IMA.claimant_id": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "App.StudyServiceDef.OLD_TRANSCRIBE.client_code": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "body_part": {
                "legalQueryOperators": [
                  "in",
                  "=",
                  "like"
                ]
              },
              "signed_time": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        },
        "imported": {
          "extra_request_options": {
            "filter_type": "group"
          },
          "apiURl": "study_service",
          "table_def": {
            "default_filter": [
              {
                "filter_only": true,
                "filter_value": "externally_created",
                "fqdn": "report_type",
                "query_operator": "="
              }
            ],
            "fields": {
              "App.StudyServiceDef.OLD_TRANSCRIBE.client_code": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "body_part": {
                "legalQueryOperators": [
                  "in",
                  "=",
                  "like"
                ]
              },
              "patient_name": {
                "legalQueryOperators": [
                  "=",
                  "like"
                ]
              },
              "service_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "signed_time": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "study_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              }
            },
            "filter_type": "group",
            "order": [],
            "uniqColsVals": []
          },
          "misc": {
            "filter_type": "group"
          }
        },
        "pa": {
          "extra_request_options": {
            "filter_type": "group"
          },
          "apiURl": "study_service",
          "table_def": {
            "default_filter": [
              {
                "filter_only": true,
                "filter_value": "NULL_QUERY",
                "fqdn": "canceled_time",
                "query_operator": "="
              },
              {
                "filter_only": true,
                "filter_value": "narrative",
                "fqdn": "report_type",
                "query_operator": "="
              },
              {
                "filter_only": true,
                "filter_value": "OLD_TRANSCRIBE OLD_PA",
                "fqdn": "App.StudyServiceDef",
                "query_operator": "="
              }
            ],
            "fields": {
              "App.StudyServiceDef.OLD_TRANSCRIBE.OLD_PA.employee_id": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "App.StudyServiceDef.OLD_TRANSCRIBE.client_code": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "body_part": {
                "legalQueryOperators": [
                  "in",
                  "=",
                  "like"
                ]
              },
              "patient_name": {
                "legalQueryOperators": [
                  "=",
                  "like"
                ]
              },
              "service_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "signed_time": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "study_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              }
            },
            "filter_type": "group",
            "order": [],
            "uniqColsVals": []
          }
        },
        "B-read": {
          "extra_request_options": {
            "filter_type": "group"
          },
          "apiURl": "study_service",
          "table_def": {
            "default_filter": [
              {
                "filter_only": true,
                "filter_value": "NULL_QUERY",
                "fqdn": "canceled_time",
                "query_operator": "="
              },
              {
                "filter_only": true,
                "filter_value": "b-read",
                "fqdn": "report_type",
                "query_operator": "="
              }
            ],
            "fields": {
              "App.StudyServiceDef.OLD_TRANSCRIBE.OLD_IMA.claimant_id": {},
              "App.StudyServiceDef.OLD_TRANSCRIBE.client_code": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "body_part": {
                "legalQueryOperators": [
                  "in",
                  "=",
                  "like"
                ]
              },
              "patient_name": {
                "legalQueryOperators": [
                  "=",
                  "like"
                ]
              },
              "service_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "signed_time": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "study_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              }
            },
            "order": [],
            "uniqColsVals": []
          }
        },
        "canceled": {
          "extra_request_options": {
            "filter_type": "group"
          },
          "apiURl": "study_service",
          "table_def": {
            "default_filter": [
              {
                "filter_only": true,
                "filter_value": "NOT_NULL_QUERY",
                "fqdn": "canceled_time",
                "query_operator": "="
              }
            ],
            "fields": {
              "App.StudyServiceDef.OLD_TRANSCRIBE.client_code": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "body_part": {
                "legalQueryOperators": [
                  "in",
                  "=",
                  "like"
                ]
              },
              "patient_name": {
                "legalQueryOperators": [
                  "=",
                  "like"
                ]
              },
              "study_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              }
            },
            "filter_type": "group",
            "order": [],
            "uniqColsVals": []
          }
        }
      },
      "finance": {
        "bills_space": {},
        "invoices_space": {
          "study_service_list": {
            "extra_request_options": {
              "invoiced": false,
              "filter_type": "group"
            },
            "table_def": {
              "default_filter": [
                {
                  "filter_only": true,
                  "filter_value": "NULL_QUERY",
                  "fqdn": "canceled_time",
                  "query_operator": "="
                }
              ],
              "fields": {
                "requester_id": {
                  "legalQueryOperators": [
                    "="
                  ]
                },
                "patient_name": {
                  "legalQueryOperators": [
                    "=",
                    "like"
                  ]
                },
                "study_date": {
                  "legalQueryOperators": [
                    "between_date",
                    "=",
                    ">",
                    "<",
                    ">=",
                    "<="
                  ]
                },
                "App.StudyServiceDef.OLD_TRANSCRIBE.client_code": {
                  "legalQueryOperators": [
                    "=",
                    "in",
                    "like"
                  ]
                },
                "body_part": {
                  "legalQueryOperators": [
                    "in",
                    "=",
                    "like"
                  ]
                }
              },
              "filter_type": "group",
              "order": [],
              "uniqColsVals": []
            },
            "apiURl": "study_service"
          },
          "invoices": {
            "table_def": {
              "fields": {
                "unique_id": {
                  "display_name": "Invoice number",
                  "legalQueryOperators": [
                    "=",
                    "in",
                    "like"
                  ]
                },
                "email": {
                  "display_name": "Client Email",
                  "legalQueryOperators": [
                    "=",
                    "in",
                    "like"
                  ]
                },
                "status": {
                  "legalQueryOperators": [
                    "=",
                    "in",
                    "like"
                  ]
                },
                "due_date": {
                  "legalQueryOperators": [
                    "between_date",
                    "=",
                    ">",
                    "<",
                    ">=",
                    "<="
                  ]
                },
                "pay_received_time": {
                  "display_name": "Pay Date",
                  "legalQueryOperators": [
                    "between_date",
                    "=",
                    ">",
                    "<",
                    ">=",
                    "<="
                  ]
                }
              },
              "filter_type": "group",
              "order": [],
              "uniqColsVals": []
            },
            "extra_request_options": {},
            "apiURl": "invoice"
          }
        }
      },
      "invoicing_worklist": {
        "invoice_config": {
          "invoice_fields": {
            "default_filter": [
              {
                "filter_only": true,
                "filter_value": "NULL_QUERY",
                "fqdn": "canceled_time",
                "query_operator": "="
              }
            ],
            "fields": {
              "requester_id": {
                "legalQueryOperators": [
                  "="
                ]
              },
              "patient_name": {
                "legalQueryOperators": [
                  "=",
                  "like"
                ]
              },
              "study_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "App.StudyServiceDef.OLD_TRANSCRIBE.client_code": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "body_part": {
                "legalQueryOperators": [
                  "in",
                  "=",
                  "like"
                ]
              }
            },
            "filter_type": "group",
            "order": [],
            "uniqColsVals": []
          },
          "invoices": {
            "fields": {
              "unique_id": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "email": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "status": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "due_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "pay_received_time": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              }
            },
            "filter_type": "group",
            "order": [],
            "uniqColsVals": []
          }
        },
        "bill_config": {
          "bills": {
            "fields": {
              "unique_id": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "email": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "status": {
                "legalQueryOperators": [
                  "=",
                  "in",
                  "like"
                ]
              },
              "due_date": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              },
              "pay_received_time": {
                "legalQueryOperators": [
                  "between_date",
                  "=",
                  ">",
                  "<",
                  ">=",
                  "<="
                ]
              }
            },
            "filter_type": "group",
            "order": [],
            "uniqColsVals": []
          }
        }
      }
    },
    "importer": {
      "right hip": "/Hip, Right, X\\-ray/i",
      "splitter_regex": "/\n{1,}\\-{3,}\n{1,}/"
    },
    "timeZones": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toront",
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg"
    ]
  }
