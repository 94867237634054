import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() label: string;
    @Input() class: string = 'button-blue';
    @Input() hasDropdown: boolean = false;
    @Input() disabled: boolean = false;

    @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

    public showDropdown: boolean;

    constructor() {
        this.showDropdown = false;
    }

    ngOnInit() {

    }

    mainButtonClick() {
        this.buttonClick.emit(true);
    }

    dropdownTriggerClick(event) {
        event.stopPropagation();
        this.showDropdown = !this.showDropdown;
    }
}