import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SeoJsonLDService {
    constructor(@Inject(DOCUMENT) private doc: Document, private meta: Meta, private title: Title) { }

    private scriptType = 'application/json+ld';

    public onNavigationEnd(includeFaq = false) {
        this.insertSchema(this.websiteSchema());
        this.insertSchema(this.organiationSchema());
        this.insertSchema(this.speakableSchema());
        if (includeFaq) {
            this.insertSchema(this.faqSchema());
        }
    }

    public onNavigationStart() {
        this.removeStructuredData();
    }

    private websiteSchema() {
        return {
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            image: [
                `${window.location.protocol}//${window.location.hostname}/assets/img/marketing/body.jpg`
            ],
            '@id': 'https://www.brightviewradiology.com/',
            name: 'Brightview Radiology',
            description: `${this.meta.getTag('name= "description"').content}`,
            address: {
                '@type': 'PostalAddress',
                streetAddress: '210 W 101st Street',
                addressLocality: 'New York',
                postalCode: 'NY 10025',
                addressCountry: 'USA'
            },
            geo: {
                '@type': 'GeoCoordinates',
                latitude: 40.797487,
                longitude: -73.968976
            },
            hasOfferCatalog: {
                '@type': 'OfferCatalog',
                name: 'Services',
                itemListElement: [
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Service',
                            name: 'Teleradiology'
                        }
                    },
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Service',
                            name: 'B-Read Services'
                        }
                    },
                    {
                        '@type': 'Offer',
                        itemOffered: {
                            '@type': 'Service',
                            name: 'Managed Radiology'
                        }
                    }
                ]
            },
            url: `${window.location.href}`,
            telephone: '212-586-5700',
            priceRange: '$$',
            openingHours: [
                'Mo-Fr 9:00-17:00']
        };
    }

    private faqSchema = () => ({
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
            {
                '@type': 'Question',
                name: 'What is the NIOSH B-Reader Program?',
                acceptedAnswer: {
                    '@type': 'Answer',
                    // tslint:disable-next-line: max-line-length
                    text: 'In 1949, the International Labour Office (ILO) declared a set of standards for analyzing and recording visual radiographic abnormalities caused by the inhalation of dust. However, despite introducing an effective classification system, B-Readers were still disagreeing with each other on their interpretations.\n\nThis led to the introduction of the NIOSH B-Reader Program in 1974 and the extensive use of a B-Reader examination in 1978.\n\nThe NIOSH-B-Reader Program is in place to identify physicians qualified to perform reliable B-Reads for coal miners and others who are at risk from the inhalation of dust. A certified NIOSH B-Reader is involved in the epidemiologic evaluation, surveillance and monitoring programs related to the risk of pneumoconiosis.\n\nThe NIOSH B-Reader Program is designed to ensure a competent and consistent level of radiographic reading is upheld. It evaluates the ability of the B-Readers to classify a test set of radiographs and consistently provide precise ILO Classifications. As it stands, there are under 300 NIOSH certified B-Readers in the US.'
                }
            },
            {
                '@type': 'Question',
                name: 'Why do I need a B-Reader?',
                acceptedAnswer: {
                    '@type': 'Answer',
                    // tslint:disable-next-line: max-line-length
                    text: 'B-Readers play a critical role in providing ongoing medical surveillance for employees who are exposed to silica dust, coal mine dust and asbestos.\n\nFollowing the Occupational Safety and Health Administration regulations, there’s been a greater need for B-Reader services.\n\nA NIOSH certified B-Reader can gauge the extent of different types of pneumoconiosis, such as asbestosis, silicosis and black lung.'
                }
            },
            {
                '@type': 'Question',
                name: 'What is the ILO Classification?',
                acceptedAnswer: {
                    '@type': 'Answer',
                    // tslint:disable-next-line: max-line-length
                    text: 'The ILO Classification is a system of classifying chest radiographs for someone with a type of pneumoconiosis. The system states the name and the extent of different types of pneumoconiosis, including asbestosis, silicosis and black lung.\n\nThe ILO Classification System is designed to create a standardized means of interpreting abnormalities in chest X-rays that are the result of long-term dust inhalation.'
                }
            },
            {
                '@type': 'Question',
                name: 'What are B-Read services?',
                acceptedAnswer: {
                    '@type': 'Answer',
                    // tslint:disable-next-line: max-line-length
                    text: 'B-Reader services are performed by a physician certified by the National Institute for Occupational Safety and Health (NIOSH). It’s the job of the NIOSH B-Reader to classify chest X-rays and interpret the extent of dust exposure and other types of pneumoconiosis. This includes black lung, silicosis, asbestosis and other occupational respiratory diseases.\n\nAt Brightview Radiology, our team follows the NIOSH B-Reader Code of Ethics and uses the ILO Classification System to ensure we can provide our clients with true and reliable B-Reads.'
                }
            }
        ]
    })

    private organiationSchema = () => ({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: 'https://www.brightviewradiology.com/',
        logo: `${window.location.protocol}//${window.location.hostname}/assets/img/logo.png`
    })

    private speakableSchema = () => ({
        '@context': 'https://schema.org/',
        '@type': 'WebPage',
        name: `${this.title.getTitle()}`,
        speakable:
        {
            '@type': 'SpeakableSpecification',
            xpath: [
                '/html/head/title',
                '/html/head/meta[@name=\'description\']/@content'
            ]
        },
        url: `${window.location.href}`
    })


    private removeStructuredData(): void {
        const els = [];
        ['structured-data', 'structured-data-org'].forEach(c => {
            els.push(...Array.from(this.doc.head.getElementsByClassName(c)));
        });
        els.forEach(el => this.doc.head.removeChild(el));
    }

    private insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
        let script;
        script = this.doc.createElement('script');
        script.setAttribute('class', className);
        script.type = this.scriptType;
        script.text = JSON.stringify(schema);
        this.doc.head.appendChild(script);
    }
}
